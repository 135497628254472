import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { BehaviorSubject, catchError, forkJoin, map, Observable, of, shareReplay, switchMap, tap, throwError } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private _baseUrl: string = environment.apiURL;
    private _authenticated: boolean = false;
    private _httpClient = inject(HttpClient);
    userCache$: Observable<any>

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    /**
     * Setter & getter for refresh token
     */
    set refreshToken(token: string) {
        localStorage.setItem('refreshToken', token);
    }

    get refreshToken(): string {
        return localStorage.getItem('refreshToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post(`${this._baseUrl}send-reset-password-email/`, { email });
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string,workspaceId:string, uid: string, token: string): Observable<any> {
        return this._httpClient.post(`${this._baseUrl}workspace/${workspaceId}/reset-password/${uid}/${token}/`, { password });
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(`${this._baseUrl}sign-in/`, credentials).pipe(
            switchMap((response: any) => {
                // Store the access token in the local storage
                this.accessToken = response.access;

                // Store the refresh token in the local storage
                this.refreshToken = response.refresh;

                // Set the authenticated flag to true
                this._authenticated = true;
                // Store the user on the user service
                return forkJoin({
                    response: of(response),
                    user: this.getUserdata()
                })
            }),
            map(({ response }) => {
                return response;
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Sign in using the token
        return this._httpClient.post(`${this._baseUrl}refresh/`, {
            refresh: this.refreshToken,
        }).pipe(
            catchError(() =>

                // Return false
                of(false),
            ),
            switchMap((response: any) => {
                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.
                if (response.access) {
                    this.accessToken = response.access;
                    this.refreshToken = response.refresh;
                }

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                return this.getUserdata();
                // return of(true);
            }),
            map(user => {
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');

        // Remove the refresh token from the local storage
        localStorage.removeItem('refreshToken');

        // Set the authenticated flag to false
        this._authenticated = false;
        
        this.userCache$ = null;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists, and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    getUserdata() {
        if (!this.userCache$) {
            this.userCache$ = this._httpClient.get(`${this._baseUrl}me`).pipe(shareReplay(1));
        }
        return this.userCache$;
    }


    private _invitationDetail: BehaviorSubject<any> = new BehaviorSubject(null);
    get _invitationDetail$(): Observable<any[]> {
        return this._invitationDetail.asObservable();
    }
    getInvitationDetail(workspaceId:string,invitationToken:string): Observable<any> {
        const payload = {
            token:invitationToken
        }
        return this._httpClient.post<any>(`${this._baseUrl}workspace/${workspaceId}/user/validate-token/`,payload).pipe(
            tap((response: any) => {
                this._invitationDetail.next(response);
            }),
        );
    }

    acceptInvitation(workspaceId:string,payload:any){
        return this._httpClient.post<any>(`${this._baseUrl}workspace/${workspaceId}/user/invitation/accept/`,payload)
    }

    switchWorkspace(payload): Observable<any> {
        return this._httpClient.post<any>(`${this._baseUrl}switch-workspace/`, payload).pipe(
            tap((response) => {
                if (response.access) {
                    this.accessToken = response.access;
                    this.refreshToken = response.refresh;
                    window.location.reload();
                }
            })
        )
    }
           
}
