import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { layoutChangeType } from './header.type';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private layoutState: BehaviorSubject<layoutChangeType> = new BehaviorSubject({ flag: 'default', data: null, backFuncRef: null });

  constructor() { }


  /**
    * Getter for alert
    */
  get layoutState$(): Observable<layoutChangeType> {
    return this.layoutState.asObservable();
  }

  /*
      * setter for alert
      */
  setLayoutState(data: layoutChangeType): void {
    this.layoutState.next(data);
  }
}
