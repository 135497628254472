<!-- Notifications toggle -->
<button mat-icon-button (click)="openPanel()" #notificationsOrigin>
    <ng-container *ngIf="unreadCount > 0">
        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
            <span
                class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-[#FF6B00] text-indigo-50 text-xs font-medium">
                {{unreadCount}}
            </span>
        </span>
    </ng-container>
    <img class="icon-size-6" src="assets/icons/bell-icon-white.svg">
</button>

<!-- Notifications panel -->
<ng-template #notificationsPanel>

    <div class="notifications w-96 p-4 flex flex-col gap-3 font-host bg-[#242837] border border-[#333844] rounded-lg">
        <div class="flex items-center gap-3">
            <span class="text-lg font-bold text-white">Notifications</span>
            <span
                class="flex items-center justify-center rounded text-sm font-normal text-[#FF6B00] min-w-6 h-6 bg-[#FFF0E6] px-1">
                {{ unreadCount }}
            </span>
        </div>


        <!-- Notifications Tabs -->
        <div class="w-full">
            <mat-tab-group mat-stretch-tabs="false" selectedIndex="1" [animationDuration]="'0'"
                (selectedTabChange)="onTabChange()">
                <mat-tab label="All">
                    <ng-template matTabContent>
                        <ng-container
                            *ngTemplateOutlet="notificationList; context: {$implicit: {type: 'all', list: allNotifications}}"></ng-container>
                    </ng-template>
                </mat-tab>
                <mat-tab label="New">
                    <ng-template matTabContent>
                        <ng-container
                            *ngTemplateOutlet="notificationList;  context: {$implicit: {type: 'new', list: newNotifications}}"></ng-container>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Archived">
                    <ng-template matTabContent>
                        <ng-container
                            *ngTemplateOutlet="notificationList; context: {$implicit: {type: 'archived', list: archivedNotifications}}"></ng-container>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</ng-template>

<ng-template #notificationList let-data>
    <div class="flex flex-col font-host max-h-120 overflow-y-auto" *ngIf="data.list.length else emptyNotification">
        @if (data.type !== 'archived') {
        <div class="flex items-center gap-2 px-3 min-h-15">
            <div class="pr-2 border-r border-[#B9B9B9] h-5 flex items-center">
                <mat-checkbox [color]="'primary'" [(ngModel)]="allSelected"
                    (change)="toggleAllNotifications()"></mat-checkbox>
            </div>

            <button class="icon-size-5 rounded-sm" (click)="archiveSelectedNotifications(data.list)">
                <img src="/assets/icons/inventory-icon-gray.svg">
            </button>

            <button class="icon-size-5">
                <img src="/assets/icons/multiselect-icon-gray.svg" (click)="markAsReadSelectedNotifications(data.list)">
            </button>
        </div>
        }

        <ng-container *ngFor="let notification of data.list; trackBy: trackByFn">
            <div class="p-3 cursor-pointer">
                <div class="flex flex-col gap-1">
                    <div class="flex items-center justify-between">
                        <div class="flex items-center gap-[10px]">
                            @if (!notification.archive) {
                            <mat-checkbox [color]="'primary'" [(ngModel)]="notification.selected"
                                (change)="updateAllSelected(data.list)"></mat-checkbox>
                            }
                            <div class="text-base font-bold text-white">
                                {{notification.sender}}
                            </div>
                        </div>

                        <div class="text-sm font-normal text-[#9297A7]">
                            {{formatTime(notification.created_at)}}
                        </div>
                    </div>

                    <div class="text-base font-normal text-[#9297A7] line-clamp-2">
                        {{notification.message}}
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <ng-template #emptyNotification>
        <div class="text-center font-host text-[#9297A7]">
            No {{data.type !== 'all' ? data.type : ''}} notifications at the moment.
        </div>
    </ng-template>

</ng-template>