import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, switchMap, take, tap } from 'rxjs';
import { SalesPersons, SessionUser, BankDetails, AccountDetails, Roles, OtherSettings, ViewerResponse, Viewer, RolesResponse, Referrer } from './settings.types';
import { environment } from 'environments/environment';
import { AuthService } from 'app/core/auth/auth.service';
import { Resource, ResourceResponse } from '../appointments/appointments.types';

@Injectable({ providedIn: 'root' })
export class SettingsService {
    // Private
    private _baseUrl: string = environment.apiURL;

    private _sessions: BehaviorSubject<SessionUser | null> = new BehaviorSubject(null);
    private _salesPersons: BehaviorSubject<SalesPersons[] | null> = new BehaviorSubject(null);
    private _viewer: BehaviorSubject<any> = new BehaviorSubject(null);
    private _bankDetails: BehaviorSubject<BankDetails> = new BehaviorSubject(null);
    private _accountDetails: BehaviorSubject<AccountDetails> = new BehaviorSubject(null);
    private _resource: BehaviorSubject<ResourceResponse> = new BehaviorSubject(null);
    private _referrers: BehaviorSubject<Referrer[]> = new BehaviorSubject(null);


    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private authService: AuthService) {
        // set initial value from auth service user data
        this.authService.getUserdata().pipe(take(1)).subscribe(userData => {
            this._accountDetails.next(userData);
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for salesPersons
     */
    get salesPersons$(): Observable<SalesPersons[]> {
        return this._salesPersons.asObservable();
    }
    /**
     * Getter for workspace
     */
    get sessions$(): Observable<SessionUser> {
        return this._sessions.asObservable();
    }
    /**
     * Getter for Viewer
     */
    get viewer$(): Observable<any> {
        return this._viewer.asObservable();
    }
    /**
     * Getter for Bank Details
     */
    get bankDetails$(): Observable<BankDetails> {
        return this._bankDetails.asObservable();
    }
    /**
     * Getter for Account Details
     */
    get accountDetails$(): Observable<AccountDetails> {
        return this._accountDetails.asObservable();
    }
    /**
    * Getter for Resources 
    */
    get resource$(): Observable<ResourceResponse> {
        return this._resource.asObservable();
    }
    /**
    * Getter for Referrers 
    */
    get referrers$(): Observable<Referrer[]> {
        return this._referrers.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Get Sales Persons
     */
    getSalesPersons(): Observable<{ count: number, next: number | null, previous: number | null, results: SalesPersons[] }> {
        return this._httpClient
            .get<any>(`${this._baseUrl}salespersons/`)
            .pipe(
                tap((response: any) => {
                    this._salesPersons.next(response.results);
                })
            );
    }
    /**
     * Add Sales Persons
     */
    addSalesPerson(payload: any): Observable<any> {
        return this._httpClient.post<any>(`${this._baseUrl}salespersons/`, payload)
    }
    /**
     * Change Sales Persons Email
     */
    changeSalesPersonEmail(salesPersonId: string, payload: any): Observable<any> {
        return this._httpClient.put<any>(`${this._baseUrl}salespersons/${salesPersonId}/change_email/`, payload)
    }
    /**
     * Deactivate Sales Persons
     */
    deactivateSalesPerson(salesPersonId: string, payload: any): Observable<any> {
        return this._httpClient.put<any>(`${this._baseUrl}salespersons/${salesPersonId}/deactivate/`, payload)
    }
    /**
     * Remove Sales Persons
     */
    removeSalesPerson(salesPersonId: string, payload: any): Observable<any> {
        return this._httpClient.put<any>(`${this._baseUrl}salespersons/${salesPersonId}/delete/`, payload)
    }


    /**
     * Get sessions
     */
    getCurrentSessions(): Observable<SessionUser[]> {
        return this._httpClient.get<SessionUser[]>('api/apps/academy/current-sessions').pipe(
            tap((response: any) => {
                this._sessions.next(response);
            }),
        );
    }

    /**
     * Create resource
     */
    createResource(payload: any): Observable<Resource> {
        return this._httpClient.post<Resource>(`${this._baseUrl}appointment/resources/`, payload).pipe(
            tap(() => {
                this.getAppointmentResources().subscribe();
            }),
        )
    }

    /**
     * Partially update resource
     */
    partiallyUpdateResource(id: number, payload: any): Observable<Resource> {
        return this._httpClient.patch<Resource>(`${this._baseUrl}appointment/resources/${id}/`, payload).pipe(
            tap(() => {
                this.getAppointmentResources().subscribe();
            }),
        )
    }

    /**
     * GET resource
     */
    getAppointmentResources(): Observable<ResourceResponse> {
        return this._httpClient.get<ResourceResponse>(`${this._baseUrl}appointment/resources`).pipe(
            tap((response: ResourceResponse) => {
                this._resource.next(response);
            })
        )
    }

    /**
     * Delete resource
     */
    deleteResource(resourceId: number): Observable<any> {
        return this._httpClient.delete<any>(`${this._baseUrl}appointment/resources/${resourceId}/`).pipe(
            tap((response: any) => {
                this.getAppointmentResources().subscribe();
            }),
        )
    }

    /**
     * Create Referrer
     */
    createReferrer(payload: any): Observable<Referrer> {
        return this._httpClient.post<Referrer>(`${this._baseUrl}referrers/`, payload).pipe(
            tap(() => {
                this.getReferrers().subscribe();
            }),
        )
    }

    /**
     * Edit Referrer
     */
    editReferrer(id: string, payload: any): Observable<Referrer> {
        return this._httpClient.put<Referrer>(`${this._baseUrl}referrers/${id}/`, payload).pipe(
            tap(() => {
                this.getReferrers().subscribe();
            }),
        )
    }

    /**
     * Delete referrer
     */
    deleteReferrer(referrerId: string): Observable<any> {
        return this._httpClient.delete<any>(`${this._baseUrl}referrers/${referrerId}/`).pipe(
            tap((response: any) => {
                this.getReferrers().subscribe();
            }),
        )
    }

    /**
     * GET Referrers
     */
    getReferrers(): Observable<Referrer[]> {
        return this._httpClient.get<Referrer[]>(`${this._baseUrl}referrers/`).pipe(
            tap((response: Referrer[]) => {
                this._referrers.next(response);
            })
        )
    }

    /**
     * Get Viewer Setting
     */
    getViewerSettings(): Observable<ViewerResponse> {
        return this._httpClient.get<ViewerResponse>(`${this._baseUrl}viewer-settings/`).pipe(
            tap((response: ViewerResponse) => {
                this._viewer.next(response.results);
            }),
        );
    }

    /**
    *  Create Viewer Setting
    */
    createViewerSettings(payload: any): Observable<Viewer> {
        return this._httpClient.post<Viewer>(`${this._baseUrl}viewer-settings/`, payload).pipe(
            tap((response: Viewer) => {
                this._viewer.next(response);
            }));
    }

    /**
    *  Partially Update Viewer Setting
    */
    partiallyUpdateViewerSettingsById(id: string, payload: any): Observable<Viewer> {
        return this._httpClient.patch<Viewer>(`${this._baseUrl}viewer-settings/${id}/`, payload);
    }

    /**
     * Get Bank Details
     */
    getBankDetails(): Observable<BankDetails> {
        return this._httpClient
            .get<BankDetails>(`${this._baseUrl}bank/`)
            .pipe(
                tap((response: any) => {
                    this._bankDetails.next(response);
                })
            );
    }
    /**
     * Update Bank Details
     */
    updateBankDetails(payload: BankDetails): Observable<any> {
        return this._httpClient.put<any>(`${this._baseUrl}bank/`, payload).pipe(tap((response: any) => {
            this._bankDetails.next(response);
        }));
    }


    /**
     * Get Account Details
     */
    // getAccountDetails(): Observable<AccountDetails> {
    //     return this._httpClient
    //         .get<AccountDetails>(`${this._baseUrl}me/`)
    //         .pipe(
    //             tap((response: any) => {
    //                 this._accountDetails.next(response);
    //             })
    //         );
    // }
    /**
    * Update Account Details
    */
    updateAccountDetails(payload: any): Observable<any> {
        return this._httpClient.post<any>(`${this._baseUrl}update-me/`, payload).pipe(tap((response: any) => {
            this._accountDetails.next(response);
        }));
    }

    /**
     * Partially Update Account Details
     */
    partiallyUpdateAccountDetails(id: string, payload: any): Observable<AccountDetails> {
        return this._httpClient
            .patch<AccountDetails>(`${this._baseUrl}users/${id}/`, payload)
            .pipe(
                tap((response: AccountDetails) => {
                    this._accountDetails.next(response);
                })
            );
    }

    acceptWorkspaceInvite(workspace_id: string): Observable<any> {
        return this._httpClient.post<any>(`${this._baseUrl}workspace/${workspace_id}/user/accept/`, {})
            .pipe(
                tap((response: any) => {
                    let userData = this._accountDetails.getValue();
                    const foundIndex = userData.workspaces.findIndex((w) => w.workspace_id === workspace_id);
                    if (foundIndex > -1) {
                        userData.workspaces[foundIndex].status = 'AC';
                    }
                    this._accountDetails.next(userData);
                })
            );
    }

    declineWorkspaceInvite(workspace_id: string): Observable<any> {
        return this._httpClient.post<any>(`${this._baseUrl}workspace/${workspace_id}/user/decline/`, {})
            .pipe(
                tap((response: any) => {
                    let userData = this._accountDetails.getValue();
                    const foundIndex = userData.workspaces.findIndex((w) => w.workspace_id === workspace_id);
                    if (foundIndex > -1) {
                        userData.workspaces[foundIndex].status = 'NA';
                    }
                    this._accountDetails.next(userData);
                })
            );
    }

    /**
    * Change Password
    */
    changePassword(payload: any): Observable<any> {
        return this._httpClient.post<any>(`${this._baseUrl}change-password/`, payload)
    }




    private _roles: BehaviorSubject<Array<Roles>> = new BehaviorSubject(null);
    /**
    * Getter for Roles
    */
    get roles$(): Observable<Array<Roles>> {
        return this._roles.asObservable();
    }
    /**
    * Get Roles
    */
    getRoles(): Observable<RolesResponse> {
        return this._httpClient.get<RolesResponse>(`${this._baseUrl}roles/`).pipe(
            tap((response: RolesResponse) => {
                this._roles.next(response.results);
            }),
        );
    }
    /**
    * Get Role Details
    */
    getRole(roleId: string = this._accountDetails.value.role.id.toString()): Observable<any> {
        return this._httpClient.get<Array<Roles>>(`${this._baseUrl}roles/${roleId}/`);
    }

    /**
    * Add Role
    */
    addRole(payload: any): Observable<Roles> {
        return this._httpClient.post<Roles>(`${this._baseUrl}roles/create/`, payload).pipe(
            tap((response: any) => {
                this.getRoles().subscribe();
            }),
        )
    }
    /**
    * Edit Role
    */
    editRole(roleId: number, payload: any): Observable<any> {
        return this._httpClient.patch<any>(`${this._baseUrl}roles/${roleId}/`, payload)
    }
    changeRoleStatus(roleId: string,): Observable<any> {
        return this._httpClient.patch<any>(`${this._baseUrl}roles/toggle-status/${roleId}/`, {}).pipe(
            tap((response: any) => {
                this.getRoles().subscribe();
                this.getRole(roleId).subscribe()
            }),
        )
    }
    deleteRole(roleId: string): Observable<any> {
        return this._httpClient.delete<any>(`${this._baseUrl}roles/${roleId}/`).pipe(
            tap((response: any) => {
                this.getRoles().subscribe();
            }),
        )
    }



    private _otherSettings: BehaviorSubject<OtherSettings> = new BehaviorSubject(null);
    get otherSettings$(): Observable<OtherSettings> {
        return this._otherSettings.asObservable();
    }
    getOtherSettings(): Observable<OtherSettings> {
        return this._httpClient.get<OtherSettings>(`${this._baseUrl}other-settings/`).pipe(
            tap((response: OtherSettings) => {
                this._otherSettings.next(response);
            }),
        );
    }
    updateOtherSettings(payload: any): Observable<OtherSettings> {
        return this._httpClient.patch<OtherSettings>(`${this._baseUrl}other-settings/`, payload).pipe(
            tap((response: OtherSettings) => {
                console.log(response)
                this._otherSettings.next(response);
            })
        );
    }
}
